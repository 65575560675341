import React, { useId } from 'react';
import PropTypes from 'prop-types';
import FormGroup from 'reactstrap/lib/FormGroup';
import classnames from 'classnames';
import { noop } from 'lodash';
import { SCROLL_DURATION, SCROLL_OFFSET } from 'site-modules/shared/constants/sub-navigation';
import { FieldError } from 'site-modules/shared/components/field-error/field-error';
import { ContentCollapse } from 'site-modules/shared/components/content-collapse/content-collapse';
import { RadioListItem } from './radio-list-item';

import './radio-list.scss';

export function RadioList({
  legend,
  list,
  name,
  tag,
  ariaRole,
  ariaLabel,
  labelledBy,
  onChange,
  checked,
  formClassName,
  legendClassName,
  formGroupClassName,
  labelContainerClassName,
  textClosed,
  textOpen,
  buttonClassName,
  labelClassName,
  labelCheckedClassName,
  labelUncheckedClassName,
  textClassName,
  checkmarkClassName,
  error,
  fieldErrorId,
  collapseAfter,
  useInlineTags,
  onClick,
  noAdRefresh,
}) {
  const idPrefix = useId();

  const errorId = fieldErrorId || `${idPrefix}-radio-error`;
  const radioListId = `radio-list-${name}`;
  const collapseNum = collapseAfter === null ? list.length : collapseAfter;
  const itemProps = {
    name,
    onChange,
    onClick,
    formGroupClassName,
    labelContainerClassName,
    checkmarkClassName,
    labelClassName,
    labelCheckedClassName,
    labelUncheckedClassName,
    textClassName,
    useInlineTags,
    noAdRefresh,
  };

  return (
    <FormGroup
      id={radioListId}
      tag={tag}
      role={ariaRole}
      className={classnames('radio-list', formClassName)}
      aria-label={ariaLabel}
      labelled-by={labelledBy}
      {...(error ? { 'aria-describedby': errorId, 'aria-invalid': true } : {})}
    >
      {!!legend && <legend className={legendClassName}>{legend}</legend>}
      <FieldError id={errorId} error={error} classes="mb-1" />
      {list.slice(0, collapseNum).map(listItem => (
        <RadioListItem
          {...itemProps}
          key={listItem.title}
          listItem={listItem}
          checked={listItem.title === checked}
          customAttrs={listItem.customAttrs}
        />
      ))}
      {list.length > collapseNum && (
        <ContentCollapse
          isEclipseFade={false}
          btnClasses={buttonClassName}
          btnContainerClasses="py-0"
          justify="justify-content-start"
          showBtnBelow
          btnTextClasses="fw-normal"
          textClosed={textClosed}
          textOpen={textOpen}
          resetParams={{
            offset: SCROLL_OFFSET,
            duration: SCROLL_DURATION,
            to: radioListId,
          }}
        >
          {list.slice(collapseNum).map(listItem => (
            <RadioListItem
              {...itemProps}
              key={listItem.title}
              listItem={listItem}
              checked={listItem.title === checked}
            />
          ))}
        </ContentCollapse>
      )}
    </FormGroup>
  );
}

RadioList.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  list: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  tag: PropTypes.string,
  ariaRole: PropTypes.string,
  ariaLabel: PropTypes.string,
  labelledBy: PropTypes.string,
  checked: PropTypes.string,
  legend: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  formClassName: PropTypes.string,
  buttonClassName: PropTypes.string,
  textOpen: PropTypes.string,
  textClosed: PropTypes.string,
  legendClassName: PropTypes.string,
  formGroupClassName: PropTypes.string,
  labelContainerClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  labelCheckedClassName: PropTypes.string,
  labelUncheckedClassName: PropTypes.string,
  textClassName: PropTypes.string,
  checkmarkClassName: PropTypes.string,
  error: PropTypes.string,
  fieldErrorId: PropTypes.string,
  collapseAfter: PropTypes.number,
  useInlineTags: PropTypes.bool,
  onClick: PropTypes.func,
  noAdRefresh: PropTypes.bool,
};

RadioList.defaultProps = {
  tag: 'fieldset',
  ariaRole: null,
  ariaLabel: null,
  labelledBy: null,
  classes: {},
  checked: '',
  legend: null,
  formClassName: null,
  buttonClassName: 'col-auto px-0_5 py-0 size-16 text-primary-darker mb-1_5 ml-1_25',
  textOpen: 'See less models',
  textClosed: 'See all models',
  legendClassName: null,
  formGroupClassName: undefined,
  labelContainerClassName: undefined,
  labelClassName: undefined,
  labelCheckedClassName: undefined,
  labelUncheckedClassName: undefined,
  textClassName: undefined,
  checkmarkClassName: undefined,
  error: null,
  fieldErrorId: null,
  collapseAfter: null,
  useInlineTags: false,
  onClick: noop,
  noAdRefresh: undefined,
};
