import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { noop } from 'lodash';
import { Col, Row } from 'reactstrap';
import TabContent from 'reactstrap/lib/TabContent';
import TabPane from 'reactstrap/lib/TabPane';
import { TAB_IDS } from 'site-modules/shared/constants/search-by-module';
import { TAB_TITLES } from 'site-modules/shared/components/search-by-module/search-by-module-constants';
import { Storage } from 'site-modules/shared/utils/storage';
import { convertParams } from 'site-modules/shared/utils/convert-params';
import { SearchMakeModelTab } from 'site-modules/shared/components/search-by-tabs/sarch-by-make-model-tab/search-by-make-model-tab';
import { SearchByTypeTab } from 'site-modules/shared/components/search-by-tabs/search-by-type-tab/search-by-type-tab';
import { SearchByPriceTab } from 'site-modules/shared/components/search-by-tabs/search-by-price-tab/search-by-price-tab';
import { ToggleButtonGroup } from 'site-modules/shared/components/inventory/toggle-button-group/toggle-button-group';
import { TabToggleButton } from 'site-modules/shared/components/inventory/tab-toggle-button/tab-toggle-button';

import './search-by-module.scss';

const SELECTION_KEY = 'selectionByModule';

export function SearchByModuleContent({
  className,
  tabsContainerClassName,
  trackingMap,
  isUsed,
  isCpo,
  hasCorePageRedirect,
  disableMakeToggle,
  disableModelToggle,
  location,
  isSmallContainer,
  showTabs,
  showRadioButtons,
  onUpdateParams,
  activeTab: initialTab,
}) {
  const [activeTab, setActiveTab] = useState(initialTab);
  const [selection, setSelection] = useState({});
  const storage = useRef();

  useEffect(() => {
    storage.current = new Storage('sessionStorage');

    const storageSelection = storage.current.get(SELECTION_KEY);
    if (storageSelection) {
      setSelection(storageSelection);

      const currentSelections = {};
      Object.entries(storageSelection).forEach(([name, value]) => {
        Object.assign(currentSelections, convertParams(name, value));
      });

      onUpdateParams(currentSelections);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setSelectionToStorage = useCallback(
    (name, value) => {
      storage.current.set(SELECTION_KEY, { ...storage.current.get(SELECTION_KEY), [name]: value });
      if (value) {
        onUpdateParams(convertParams(name, value));
      }
    },
    [onUpdateParams]
  );

  const {
    make,
    model,
    vehicleType,
    vehicleTypePriceRange: { range, minPrice, maxPrice } = {},
    priceRange,
    loanPriceRange,
    leasePriceRange,
  } = selection;

  const tabsContent = [
    {
      tabId: TAB_IDS.MAKE_MODEL_TAB,
      label: TAB_TITLES[TAB_IDS.MAKE_MODEL_TAB],
      trackingId: trackingMap.tabSelectTracking(TAB_IDS.MAKE_MODEL_TAB),
      content: (
        <SearchMakeModelTab
          make={make}
          model={model}
          tracking={trackingMap}
          isUsed={isUsed}
          isCpo={isCpo}
          hasCorePageRedirect={hasCorePageRedirect}
          disableMakeToggle={disableMakeToggle}
          disableModelToggle={disableModelToggle}
          onSelectionUpdate={setSelectionToStorage}
          location={location}
          isSmallContainer={isSmallContainer}
          showRadioButtons={showRadioButtons}
        />
      ),
    },
    {
      tabId: TAB_IDS.TYPE_TAB,
      label: TAB_TITLES[TAB_IDS.TYPE_TAB],
      trackingId: trackingMap.tabSelectTracking(TAB_IDS.TYPE_TAB),
      content: (
        <SearchByTypeTab
          priceRange={range}
          minPrice={minPrice}
          maxPrice={maxPrice}
          vehicleType={vehicleType}
          tracking={trackingMap}
          isUsed={isUsed}
          isCpo={isCpo}
          onSelectionUpdate={setSelectionToStorage}
          location={location}
          isSmallContainer={isSmallContainer}
          showRadioButtons={showRadioButtons}
        />
      ),
    },
    {
      tabId: TAB_IDS.PRICE_TAB,
      label: TAB_TITLES[TAB_IDS.PRICE_TAB],
      trackingId: trackingMap.tabSelectTracking(TAB_IDS.PRICE_TAB),
      content: (
        <SearchByPriceTab
          tracking={trackingMap}
          isUsed={isUsed}
          isCpo={isCpo}
          priceRange={priceRange}
          loanPriceRange={loanPriceRange}
          leasePriceRange={leasePriceRange}
          onSelectionUpdate={setSelectionToStorage}
          location={location}
          isSmallContainer={isSmallContainer}
          showRadioButtons={showRadioButtons}
        />
      ),
    },
  ].filter(({ tabId }) => showTabs.includes(tabId));

  const handleTabChange = useCallback((_, tabId) => {
    setActiveTab(tabId);
  }, []);

  return (
    <div className={classnames('search-by-module', className)} data-tracking-parent="edm-entry-inventory-search">
      <div className={tabsContainerClassName}>
        <Row noGutters className="w-100">
          <Col xs={12} md={10}>
            <Row noGutters className="w-100 align-items-center">
              <Col xs={12} md="auto" className={classnames('size-14 text-cool-gray-30 fw-medium mb-0_5 mb-md-0 mr-1')}>
                Search by:
              </Col>
              <Col xs={12} md={9}>
                <ToggleButtonGroup
                  onChange={handleTabChange}
                  value={activeTab}
                  role="tablist"
                  className="search-by-module-tabs"
                >
                  {tabsContent.map(({ label, tabId, trackingId }) => (
                    <TabToggleButton key={tabId} data-tracking-id={trackingId} value={tabId} id={tabId}>
                      {label}
                    </TabToggleButton>
                  ))}
                </ToggleButtonGroup>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <TabContent activeTab={activeTab}>
        {tabsContent.map(({ tabId, content }) => (
          <TabPane
            tabId={tabId}
            key={`tab-${tabId}`}
            id={tabId}
            role="tabpanel"
            tabIndex={activeTab === tabId ? 0 : -1}
            aria-hidden={activeTab !== tabId}
            aria-labelledby={`${tabId}-tab`}
          >
            {content}
          </TabPane>
        ))}
      </TabContent>
    </div>
  );
}

SearchByModuleContent.propTypes = {
  className: PropTypes.string,
  tabsContainerClassName: PropTypes.string,
  trackingMap: PropTypes.shape({}).isRequired,
  activeTab: PropTypes.string,
  isUsed: PropTypes.bool,
  isCpo: PropTypes.bool,
  hasCorePageRedirect: PropTypes.bool,
  disableMakeToggle: PropTypes.bool,
  disableModelToggle: PropTypes.bool,
  location: PropTypes.shape({}).isRequired,
  isSmallContainer: PropTypes.bool,
  onUpdateParams: PropTypes.func,
  showTabs: PropTypes.arrayOf(PropTypes.string),
  showRadioButtons: PropTypes.bool,
};

SearchByModuleContent.defaultProps = {
  className: null,
  tabsContainerClassName: null,
  activeTab: TAB_IDS.MAKE_MODEL_TAB,
  isUsed: false,
  isCpo: false,
  hasCorePageRedirect: false,
  disableMakeToggle: false,
  disableModelToggle: false,
  isSmallContainer: false,
  onUpdateParams: noop,
  showTabs: [TAB_IDS.MAKE_MODEL_TAB, TAB_IDS.TYPE_TAB, TAB_IDS.PRICE_TAB],
  showRadioButtons: false,
};
